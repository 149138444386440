<template>
  <v-card class="mb-4">
      <v-card-text>
        <h3 class="mb-5">Facturen doorsturen</h3>
        <v-row>
          <v-col class="pa-0" cols="12">
            <v-select
              v-if="auctions"
              v-model="selectedAuction"
              :items="auctions"
              label="Selecteer een veiling"
              class="property-filter"
              hide-no-data
              item-value="_id"
              item-text="Data.Name.Value.Nl"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <v-btn
              :disabled="!selectedAuction"
              :loading="uploadingInvoices"
             @click="submitToFactuursturen()"
            >
              Facturen doorsturen naar FactuurSturen <v-icon right dark>cloud_upload</v-icon>
            </v-btn>

            <v-alert v-if="uploadingInvoicesError.length > 0" class="mt-3" color="error" icon="warning" :value="true">
              <span v-html="uploadingInvoicesError"></span>
            </v-alert>
            <v-alert v-if="loadVerifiedWinnersError.length > 0" class="mt-3" color="error" icon="warning" :value="true">
              <span v-html="loadVerifiedWinnersError"></span>
            </v-alert>
            <v-alert type="success" v-if="uploadingInvoicesSuccessMesage.length > 0" class="mt-3" icon="check" :value="true">
              {{ uploadingInvoicesSuccessMesage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>
import request from '../../request'
export default {
data () {
    return {
      auctions: null,
      selectedAuction: null,
      uploadingInvoices:false,
      uploadingInvoicesError:"",
      verifiedWinners:[],
      loadVerifiedWinnersError:"",
      uploadingInvoicesSuccessMesage: "",
    }
  },
  created () {
    request.get("/pagecontent/5fa3b4eaae990f3950fe0687", null, (res) => {
      if (res.success) {
        this.auctions = res.data
      }
    })
  },
   methods:{
    submitToFactuursturen(){
          this.uploadingInvoices = true
          this.uploadingInvoicesSuccessMesage = ""
          this.uploadingInvoicesSuccessMesage = ""

          request.post("/custom/create-invoices?auctionId="+ this.selectedAuction.$oid, null, (res) => {
              this.uploadingInvoices = false
              if (!res.success) {
                this.uploadingInvoicesError = res.message
              }else{
                this.uploadingInvoicesSuccessMesage = res.data
              }
          })
    }
   }
}
</script>

<style>

</style>
